@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Mona Sans";
  src: url("/fonts/mona-sans/MonaSans.woff2") format("woff2-variations");
  font-weight: 200 900;
  font-stretch: 75% 125%;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Mona Sans";
  src: url("/fonts/mona-sans/MonaSans.woff2") format("woff2-variations");
  font-weight: 200 900;
  font-stretch: 75% 125%;
  font-style: italic;
  font-display: swap;
}
 
@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 240 10% 3.9%;

    --card: 0 0% 100%;
    --card-foreground: 240 10% 3.9%;
 
    --popover: 0 0% 100%;
    --popover-foreground: 240 10% 3.9%;
 
    --primary: 240 5.9% 10%;
    --primary-foreground: 0 0% 98%;
 
    --secondary: 240 4.8% 95.9%;
    --secondary-foreground: 240 5.9% 10%;
 
    --muted: 240 4.8% 95.9%;
    --muted-foreground: 240 3.8% 46.1%;
 
    --accent: 240 4.8% 95.9%;
    --accent-foreground: 240 5.9% 10%;
 
    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 0 0% 98%;

    --border: 240 5.9% 90%;
    --input: 240 5.9% 90%;
    --ring: 240 10% 3.9%;
 
    --radius: 0.5rem;
  }
 
  /*
  .dark {
    --background: 240 10% 3.9%;
    --foreground: 0 0% 98%;
 
    --card: 240 10% 3.9%;
    --card-foreground: 0 0% 98%;
 
    --popover: 240 10% 3.9%;
    --popover-foreground: 0 0% 98%;
 
    --primary: 0 0% 98%;
    --primary-foreground: 240 5.9% 10%;
 
    --secondary: 240 3.7% 15.9%;
    --secondary-foreground: 0 0% 98%;
 
    --muted: 240 3.7% 15.9%;
    --muted-foreground: 240 5% 64.9%;
 
    --accent: 240 3.7% 15.9%;
    --accent-foreground: 0 0% 98%;
 
    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 0 0% 98%;
 
    --border: 240 3.7% 15.9%;
    --input: 240 3.7% 15.9%;
    --ring: 240 4.9% 83.9%;
  }
  */
    :root {
        /* Darker purple tones */
        --background: 281 56% 15%; /* Dark purple */
        --foreground: 0 0% 98%;    /* White */

        --card: 281 56% 15%; /* Dark purple (same as background) */
        --card-foreground: 0 0% 98%; /* White */

        --popover: 281 56% 15%; /* Dark purple (same as background) */
        --popover-foreground: 0 0% 98%; /* White */

        /* Other colors */
        --primary: 0 0% 98%;    /* White */
        --primary-foreground: 281 56% 15%; /* Dark purple */

        --secondary: 279 32% 32%; /* Purple-gray */
        --secondary-foreground: 0 0% 98%; /* White */

        --muted: 279 32% 32%; /* Purple-gray */
        --muted-foreground: 240 5% 64.9%; /* Light blue-gray */

        --accent: 279 32% 32%; /* Purple-gray */
        --accent-foreground: 0 0% 98%; /* White */

        --destructive: 0 62.8% 30.6%; /* Red */
        --destructive-foreground: 0 0% 98%; /* White */

        /* Inputs and borders */
        --border: 279 32% 32%; /* Purple-gray */
        --input: 279 32% 32%; /* Purple-gray */
        --ring: 240 4.9% 83.9%; /* Light blue */
    }
}
 
@layer base {
  * {
    @apply border-border;
  }
  body {
    @apply bg-background text-foreground;
  }
}

.bg-radial-main {
  background: var(--bg-tertiary);
  background: radial-gradient(
    circle,
    rgba(var(--bg-tertiary), 1) 36%,
    hsl(283 28% 13% / 1) 100%
  );
}

.text-extra-condensed {
  font-stretch: 75%;
}

.text-extra-huge {
  font-size: 5rem;

  @screen lg {
    font-size: 10rem; /* Font size on 'lg' screens and above */
  }

  @screen xl {
    font-size: 15rem;
  }
}

.top-10px {
  top: 10px;
}
